import "./App.scss";

function App() {
	return (
    <>
    <div className="header">
      <img src='./images/logo.png' alt="logo"/>
    </div>
		<div className="body">
			<div className="site">
				<a href="https://lovelocalmusicwith.us/">
					Love Local Music With Us - Website
				</a>
			</div>
			<div className="site">
				<a href="https://www.facebook.com/groups/210341205729667">
					Love Local Music With Us - Facebook
				</a>
			</div>
			<div className="site">
				<a href="https://www.facebook.com/BandsClubsofTriangle">
					Band and Clubs of the Triangle - Facebook
				</a>
			</div>
			<div className="site">
				<a href="https://www.instagram.com/bandsandclubsofthetriangle">
					Band and Clubs of the Triangle - Instagram
				</a>
			</div>
			<div className="site">
				<a href="https://www.facebook.com/profile.php?id=100057450437858">
					NC Good Eats - Facebook
				</a>
			</div>
		</div>
    </>
	);
}

export default App;
